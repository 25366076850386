const baseUrl = "https://api.celebrity-souq.com";


export const environment = {
  production: true,
  apiUrl: `${baseUrl}/api/admin`,
  baseUrl: baseUrl,
  perPage: 25,
  dialogWidth: '550px',
  dialogHeight: '750px',
  titlePrefix: 'Celebrity',
  dateFormat: 'yyyy/MM/dd',
  datetimeFormat: 'yyyy/MM/dd hh:mm a'
};
